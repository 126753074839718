// This file is automatically compiled by Webpack.
// Anything included in this file is loaded and run as soon as the page loads,
// so it should only include the bare minimum to load and execute.


  import * as Sentry from '@sentry/browser';
  Sentry.init({
    dsn: 'https://b2c396c72ad9dd361fad71dab1b46154@o250825.ingest.sentry.io/3014851',
    environment: 'production',
    release: '8ab5eaeaa839886d0eea1565863e74850aa02191',
    /**
     * Rate limit JSON parse errors. Previous error reports have shown that
     * these errors come in batches, where each batch contains several thousand
     * occurrences.
     *
     * graphql-client has been patched to raise these errors with the full
     * response body included, so that we can observe the actual response
     * and determine where the response originates from. While this is active,
     * we don't need to capture every single event.
     *
     * @see https://docs.sentry.io/platforms/javascript/configuration/filtering/
     * @see ./patches/graphql-client+2.0.1.patch
     * @param event The Sentry event
     * @returns The event to report, or null to filter out the event
     */
    beforeSend(event) {
      const error = event.exception.values[0];
      if (
        error &&
        error.value &&
        error.value.match(/^Failed to parse JSON/) &&
        Math.random() > 0.15) {
          return null;
      }

      return event;
    }
  });

  Sentry.configureScope(scope => scope.setExtra("runtime", "javascript"));

